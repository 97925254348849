.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(to bottom, #FF8473, #FF6347);

  } 

  .card {
    width: 400px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    padding: 10px 20px 0px 20px;
  }
  
  .logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 300px;
    height: auto;
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    border-top: 1px solid #f0f5ff;
    text-align: center;
  }
  